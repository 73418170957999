<template>
  <div>
    <v-row justify="center" class="my-2">
      <v-img max-width="300" src="@/assets/biodf/BioDF-textlogo.png"></v-img>
    </v-row>
    <v-row justify="center">
      <p class="primary--text">We simplify access to biological data</p>
    </v-row>
    <!-- <DashboardStat /> -->
    <br>
    <DashboardLogs />
    <DashboardFooter />
  </div>
</template>

<script>
import DashboardStat from './DashboardStat'
import DashboardFooter from './DashboardFooter'
import DashboardLogs from './DashboardLogs'
export default {
  name: 'DashboardMain',
  components: {
    DashboardStat,
    DashboardFooter,
    DashboardLogs
  },
  data() {
    return {
      search: ''
    }
  },
  methods: {
    queryKendra() {
      this.$gtag.event(`Search`, {
        event_category: 'Dashboard',
        event_label: `A semantic search is performed from dashboard`
      })
      this.$router.push({
        name: 'SemanticSearch',
        query: { search: this.search }
      })
    }
  }
}
</script>