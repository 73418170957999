<template>
  <v-card class="my-10">
    <v-card-title class="justify-center">Brought to you by:</v-card-title>

    <v-row class="mt-n4">
      <v-col align="center"
        ><v-img src="@/assets/sponsors/nserc.png" max-width="200"></v-img
      ></v-col>
      <v-col align="center">
        <v-img
          src="@/assets/sponsors/alberta-innovates.png"
          max-width="200"
        ></v-img>
      </v-col>
      <v-col align="center">
        <v-img
          src="@/assets/sponsors/uofa-engg.png"
          max-width="200"
          class="my-4"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'PhredFooter'
}
</script>

<style></style>