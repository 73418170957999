<template>
    <v-container>

    <v-tabs v-model="tab" background-color="transparent">
      <v-tab v-for="tab in tabs" :key="tab.name">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    
    <div v-if="tab === 0">
      <v-card min-height="150" class="my-4">
        <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=2" width="375" height="150" frameborder="0"></iframe>
      &nbsp;
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=4" width="375" height="150" frameborder="0"></iframe>
      &nbsp;
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=6" width="375" height="150" frameborder="0"></iframe>
      </v-card>

      <v-card min-height="400" class="my-4">
        <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=8" width="1125" height="400" frameborder="0"></iframe>
      </v-card>

      <v-card min-height="400">
        <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=10" width="1125" height="400" frameborder="0"></iframe>
      </v-card>

      <v-card min-height="350" class="my-4">
        <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=&from=now-30d&to=now&theme=light&panelId=12" width="375" height="350" frameborder="0"></iframe>
      &nbsp;
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=14" width="375" height="350" frameborder="0"></iframe>
      &nbsp;
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=16" width="375" height="350" frameborder="0"></iframe>
      </v-card>

      <v-card min-height="400" class="my-4">
        <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=18" width="562.5" height="400" frameborder="0"></iframe>
      &nbsp;
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=20" width="562.5" height="400" frameborder="0"></iframe>
      </v-card>
    </div>

    <div v-if="tab === 1">
        <v-tabs v-model="subtab" background-color="transparent">
      <v-tab v-for="subtab in subtabs" :key="subtab.name">{{
        subtab.name
      }}</v-tab>
    </v-tabs>

    <div v-if="subtab === 0">
    <v-card min-height="150" class="my-4">
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/f95BGEo7k/genomedownloaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=2" width="562.5" height="150" frameborder="0"></iframe>
    &nbsp;
    <iframe src="https://grafananginx.bio-conversion.org/d-solo/f95BGEo7k/genomedownloaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=4" width="562.5" height="150" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="350" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/f95BGEo7k/genomedownloaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=6" width="1125" height="350" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="350" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/f95BGEo7k/genomedownloaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=8" width="1125" height="350" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="400" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/f95BGEo7k/genomedownloaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=10" width="562.5" height="400" frameborder="0"></iframe>
    &nbsp;
    <iframe src="https://grafananginx.bio-conversion.org/d-solo/f95BGEo7k/genomedownloaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=12" width="562.5" height="400" frameborder="0"></iframe>
    </v-card>
    </div>

    <div v-if="subtab === 1">

      <v-card min-height="150" class="my-4">
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/Y3Io7PTnz/genomeprocessingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=2" width="562.5" height="150" frameborder="0"></iframe>
    &nbsp;
    <iframe src="https://grafananginx.bio-conversion.org/d-solo/Y3Io7PTnz/genomeprocessingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=4" width="562.5" height="150" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="350" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/Y3Io7PTnz/genomeprocessingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=6" width="1125" height="350" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="350" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/Y3Io7PTnz/genomeprocessingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=8" width="1125" height="350" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="400" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/Y3Io7PTnz/genomeprocessingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=10" width="562.5" height="400" frameborder="0"></iframe>
    &nbsp;
    <iframe src="https://grafananginx.bio-conversion.org/d-solo/Y3Io7PTnz/genomeprocessingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=12" width="562.5" height="400" frameborder="0"></iframe>
    </v-card>
    </div>

    <div v-if="subtab === 2">

      <v-card min-height="150" class="my-4">
      <iframe src="https://grafananginx.bio-conversion.org/d-solo/_2yS_0Tnz/genomeuploaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=6" width="562.5" height="150" frameborder="0"></iframe>
    &nbsp;
    <iframe src="https://grafananginx.bio-conversion.org/d-solo/_2yS_0Tnz/genomeuploaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=4" width="562.5" height="150" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="350" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/_2yS_0Tnz/genomeuploaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=8" width="1125" height="350" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="350" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/_2yS_0Tnz/genomeuploaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=10" width="1125" height="350" frameborder="0"></iframe>
    </v-card>

    <v-card min-height="400" class="my-4">
     <iframe src="https://grafananginx.bio-conversion.org/d-solo/_2yS_0Tnz/genomeuploaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=12" width="562.5" height="400" frameborder="0"></iframe>
    &nbsp;
    <iframe src="https://grafananginx.bio-conversion.org/d-solo/_2yS_0Tnz/genomeuploaddashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=2" width="562.5" height="400" frameborder="0"></iframe>
    </v-card>

  
    </div>
    </div>


    </v-container>
    
</template>

<script>
    export default {
        data() {
            return {
                tab: null,
                tabs: [
                    {name: 'Article Pipeline'},
                    {name: 'Genome Pipeline'}
                ],
                subtab: null,
                subtabs: [
                    {name: 'Download'},
                    {name: 'Processing'},
                    {name: 'Upload'}
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>