<template>
  <v-container>
    <DashboardMain />
  </v-container>
</template>
<script>
import UserPool from '@/plugins/UserPool'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import DashboardMain from '@/components/dashboard/DashboardMain'

export default {
  name: 'Dashboard',
  components: {
    DashboardMain
  },
  methods: {
    updateSeenFlag() {
      let attributeList = [
        new CognitoUserAttribute({
          Name: 'custom:seen-dashboard',
          Value: 'true'
        })
      ]
      const user = UserPool.getCurrentUser()
      user.getSession((err, session) => {
        user.updateAttributes(attributeList, (err, result) => {
          if (err) {
            const notification = {
              type: 'error',
              message: err.message
            }
            this.$store.dispatch('addNotification', notification)
          }
        })
      })
    }
  },
  created() {
    let seenDashboard = this.$store.state.user.userAttributes[
      'custom:seen-dashboard'
    ]
    if (seenDashboard === 'false' || !seenDashboard) {
      const notification = {
        type: 'info',
        message: 'Welcome to BioDF!'
      }
      this.$store.dispatch('addNotification', notification)
      this.updateSeenFlag()
      this.$store.dispatch('getSession')
    }
  }
}
</script>
