<template>
  <v-row>
    <v-col
      class="mt-2"
      cols="12"
      sm="6"
      lg="3"
      v-for="(stat, i) in stats"
      :key="stat.name"
    >
      <v-card min-height="100">
        <v-row align="center">
          <v-col class="ml-2 mr-n8">
            <v-card-title class="primary--text text-h3">
              {{
                i === 0
                  ? tweenedNumber0.toFixed(0)
                  : i === 1
                  ? tweenedNumber1.toFixed(0)
                  : i === 2
                  ? tweenedNumber2.toFixed(0)
                  : tweenedNumber3.toFixed(0)
              }}
            </v-card-title>
          </v-col>
          <v-col>
            <v-card-subtitle class="primary--text font-weight-bold">{{
              stat.name
            }}</v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import gsap from "gsap";
import {
  listPhages,
  listBacterialHosts,
  listPhageFamilies,
  listReceptorLocations,
} from "@/graphql/queries.js";
export default {
  name: "DashboardStat",
  data() {
    return {
      number0: 0,
      tweenedNumber0: 0,
      number1: 1,
      tweenedNumber1: 0,
      number2: 0,
      tweenedNumber2: 0,
      number3: 0,
      tweenedNumber3: 0,
      number4: 0,
      tweenedNumber4: 0,
      stats: [
        { name: "Phage Receptors", value: 0 },
        { name: "Phage Bacterial Hosts", value: 0 },
        // { name: 'Bacterial Strains', value: 213 },
        // { name: 'Bacterial Genomes', value: 224 }
        { name: "Phage Families", value: 0 },
        { name: "Location of receptors", value: 0 },
      ],
    };
  },
  watch: {
    number0(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber0: newValue,
      });
    },
    number1(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber1: newValue,
      });
    },
    number2(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber2: newValue,
      });
    },
    number3(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber3: newValue,
      });
    },
  },
  created() {
    this.updateStats();
  },
  methods: {
    updateStats() {
      // fetch number of Phage Receptors
      this.$apollo
        .query({
          query: listPhages,
          variables: {
            limit: 10000,
          },
        })
        .then((data) => {
          // console.log(data);
          this.number0 = data.data.listPhages.items.length;
        })
        .catch((err) => {
          console.log(err);
        });

      // fetch number of Bacterial Hosts
      this.$apollo
        .query({
          query: listBacterialHosts,
          variables: {
            limit: 10000,
          },
        })
        .then((data) => {
          this.number1 = data.data.listBacterialHosts.items.length;
        })
        .catch((err) => {
          console.log(err);
        });

      // fetch number of Phage Families
      this.$apollo
        .query({
          query: listPhageFamilies,
          variables: {
            limit: 10000,
          },
        })
        .then((data) => {
          this.number2 = data.data.listPhageFamilies.items.length;
        })
        .catch((err) => {
          console.log(err);
        });

      // fetch number of Receptor Locations
      this.$apollo
        .query({
          query: listReceptorLocations,
          variables: {
            limit: 10000,
          },
        })
        .then((data) => {
          this.number3 = data.data.listReceptorLocations.items.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
